export const environment = {
  production: false,
  baseURL:
    'https://dev-stopover.adminmodules.etihad.ae/ada-services/stopoveradmin',
  OKTAClientID: '0oa21aywrfsiVqjcu0h8',
  OKTAIssuer: 'https://etihad.oktapreview.com',
  OKTArediRectUri: 'https://dev-stopover.adminmodules.etihad.ae/login/callback',
  coreDataURL: 'https://www.etihad.com',
  env: 'DEV',
};
