import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth, { UserClaims } from '@okta/okta-auth-js';
import {
  BehaviorSubject,
  Observable
} from 'rxjs';
import { environment } from '../../environments/environment';
@Injectable({ providedIn: 'root' })
export class UserInfoService {
  public isAuthenticated$!: Observable<boolean>;
  public isUserInfoUpdated$ = new BehaviorSubject<boolean>(false);
  private http = inject(HttpClient);
  private router = inject(Router);
  userInfoDetails = <UserClaims>{};
  BASE_URL = environment.baseURL;
  constructor(@Inject(OKTA_AUTH) public oktaAuth: OktaAuth) {}
  
  getAccessToken(payload: unknown) {
    return this.http.post(`${this.BASE_URL}/manage/token/v1`, payload);
  }

  async logout() {
    await this.oktaAuth.signOut();
    this.oktaAuth.tokenManager.clear();
    localStorage.clear();
    this.router.navigate(['/login']);
  }
}
