import {
  InjectionToken,
  NgModule
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OktaAuthModule } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { NavigationModule } from './components/navigation/navigation.module';
import { AuthInterceptor } from './interceptors/auth-interceptor.service';
import { ErrorInterceptor } from './interceptors/error-interceptor.service';
import { LoaderInterceptor } from './interceptors/loader-interceptor.service';
import { TokenResolver } from './resolver/token.resolver';

const oktaAuth = new OktaAuth({
  issuer: environment.OKTAIssuer,
  clientId: environment.OKTAClientID,
  redirectUri: environment.OKTArediRectUri,
});

export const APP_BASE_URL = new InjectionToken<string>('app.baseUrl');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HeaderComponent,
    NavigationModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    OktaAuthModule.forRoot({ oktaAuth }),
  ],
  providers: [
    provideHttpClient(withInterceptors([AuthInterceptor])),
    TokenResolver,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    { provide: APP_BASE_URL, useValue: environment.baseURL },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
