import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition
} from '@angular/material/snack-bar';
import { Observable, throwError,of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SnackbarService } from '../services/snackbar.service';
import { ERROR } from '../shared/const/snackbar.const';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private snackbarService = inject(SnackbarService);

  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  durationInSeconds = 5;

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          // Handle HTTP errors
          this.snackbarService.showSnackBar(
            `${error.name}: ${error.status} - ${error.statusText}`,
            ERROR
          );
        } else {
          // Handle non-HTTP errors
          this.snackbarService.showSnackBar(`Unhandelled error occuerd!!`,ERROR);
        }

        // Rethrow the error to propagate it
        return throwError(() => of(error));
      })
    );
  }
}
