<mat-toolbar class="header-container">
  <div>
    <button
      mat-icon-button
      color="primary"
      aria-label="Example icon button with a menu icon"
      (click)="openSideNav()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div>
    <img src="../../assets/etihad-logo.png" width="100" alt="Logo"/>
  </div>
  <div>
    <button
      mat-icon-button
      [matMenuTriggerFor]="menu"
      aria-label="Example icon-button with a menu"
      class="user-icon-button">
      @if (initials) {
        <span class="initials">{{ initials }}</span>
      }
      @else{
        <mat-icon class="user-icon"> account_circle</mat-icon>
      }
    </button>
    <mat-menu #menu="matMenu">
      @if(userName){
        <span mat-menu-item disabled class="user-name"> 
          <mat-icon>account_circle</mat-icon>
          <span>{{userName}}</span>
        </span>
      }
      <button mat-menu-item (click)="logout()">
        <mat-icon>logout</mat-icon>
        <span>Logout</span>
      </button>
    </mat-menu>
  </div>
</mat-toolbar>
