import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MenuListItemComponent } from './menu-list-item/menu-list-item.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { NavigationComponent } from './navigation.component';

@NgModule({
  declarations: [NavigationComponent, MenuListItemComponent],
  imports: [
    CommonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    RouterModule,
  ],
  exports: [NavigationComponent],
})
export class NavigationModule {}
