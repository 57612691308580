@if(userInfoService.isAuthenticated$ | async){
<ng-container>
  <app-header></app-header>
  <app-navigation>
    <router-outlet></router-outlet>
  </app-navigation>
</ng-container>
}@else{
<div class="non-login-container">
  <router-outlet></router-outlet>
</div>
}

@if(loaderService.isLoading$ | async){
<div class="spinner-overlay">
  <mat-spinner diameter="50"></mat-spinner>
</div>
}