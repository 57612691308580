import { Injectable, inject } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { UserInfoService } from '../services/user-info.service';

interface ITokenPayload {
  accessToken: string;
  idToken: string;
}

@Injectable({
  providedIn: 'root',
})
export class TokenResolver {
  
  private userInfoService = inject(UserInfoService);
  
  resolve(): Observable<unknown> {
    let payload:ITokenPayload = {
      accessToken: '',
      idToken: ''
    };
    const tokenData = localStorage.getItem('okta-token-storage')
    if(tokenData && typeof tokenData == 'string'){
        const idToken = (JSON.parse(tokenData))?.idToken.idToken;
        const accessToken = (JSON.parse(tokenData))?.accessToken.accessToken;
        payload = {
            accessToken: accessToken,
            idToken: idToken,
        }
    }
   
    return this.userInfoService.getAccessToken(payload).pipe(
      catchError((error) => {
        if(error){
          this.userInfoService.logout();
        }
       return error
      })
    );
  }
}
