<a mat-list-item [ngStyle]="{ 'padding-left': depth * 12 + 'px' }" (click)="onItemSelected(item)"
  [ngClass]="checkForActiveRouterLink(item.route) ? 'active-link' : ''">
  <div class="menu-list-item">
    @if(item.iconName){
    <div class="m-05">
      <mat-icon class="nav-item-icon">{{ item.iconName }}</mat-icon>
    </div>
    }
    <div class="m-05">
      <span class="nav-item-name">
        {{ item.displayName }}
      </span>
    </div>
    <div class="m-05">
      @if(item.children && item.children.length){
      <span fxFlex class="nav-item-chevron">
        <span fxFlex></span>
        <mat-icon [@indicatorRotate]="expanded ? 'expanded' : 'collapsed'">
          expand_more
        </mat-icon>
      </span>
      }
    </div>
  </div>
</a>
@if(expanded){
<div>
  <app-menu-list-item *ngFor="let child of item.children" [item]="child" [depth]="depth + 1">
  </app-menu-list-item>
</div>
}