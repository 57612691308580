<section class="login-container">
  <div class="login-card mat-elevation-z8">
    <div class="login-logo">
      <img src="../../assets/etihad-logo.png" alt="etihad-logo" />
    </div>
    <div>
      <button mat-raised-button color="primary" (click)="onSignIn()">
        OKTA Login
      </button>
    </div>
  </div>
</section>
