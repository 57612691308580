<mat-drawer-container class="example-container" autosize>
  <mat-drawer #drawer class="example-sidenav" mode="side">
    <mat-nav-list>
      @for(item of navItems; track item) {
      <app-menu-list-item [item]="item"></app-menu-list-item>
      }
    </mat-nav-list>
  </mat-drawer>
  <div class="sidenav-content">
    <ng-content></ng-content>
  </div>
</mat-drawer-container>