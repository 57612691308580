import {
  HttpErrorResponse,
  HttpEvent,
  HttpInterceptorFn,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, from, tap, of, throwError, map } from 'rxjs';
import { OKTA_AUTH } from '@okta/okta-angular';

export const AuthInterceptor: HttpInterceptorFn = (req, next) => {
  const oktaAuth = inject(OKTA_AUTH);
  const router = inject(Router);
  return next(req).pipe(
    catchError((err: HttpErrorResponse) => {
      if (err.status === 401) {
        console.error('Unauthorized request:', err);
        return from(oktaAuth.signOut()).pipe(
          tap(() => {
            oktaAuth.tokenManager.clear();
            router.navigate(['login']);
          }),
          map(() => ({}) as unknown as HttpEvent<unknown>)
        );
      } else {
        return throwError(() => of(err));
      }
    })
  );
};
