import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { LoginComponent } from './components/login/login.component';
import { TokenResolver } from './resolver/token.resolver';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'stopover',
    pathMatch: 'full',
  },
  {
    path: 'stopover',
    loadChildren: () =>
      import('./stopover/stopover.module').then(m => m.StopoverModule),
    canActivate: [OktaAuthGuard],
    // resolve: { TokenResolver },
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'login/callback',
    component: OktaCallbackComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { bindToComponentInputs: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
